import copy from 'copy-to-clipboard';
import { useMemo } from 'react';

import { type SessionTrack, SessionUtils } from '../../types';
import { type Action, ActionSheet } from '../ActionSheet';
import { CopyIcon } from '../icons/CopyIcon';
import { EditIcon } from '../icons/EditIcon';
import { SearchIcon } from '../icons/SearchIcon';

type Keys = 'copyStreamId' | 'inspectSession' | 'editSession';

export type SessionActionSheetConfig = {
  showEdit: boolean;
  showCopyStreamId: boolean;
  showInspect: boolean;
};

export function SessionActionSheet(props: {
  config?: SessionActionSheetConfig;
  sessionTrack: SessionTrack;
  onEdit: () => void;
}): JSX.Element {
  const { sessionTrack, onEdit, config } = props;
  const actions: Action<Keys>[] = useMemo(
    () =>
      [
        config?.showEdit && {
          kind: 'button',
          key: 'editSession',
          icon: <EditIcon />,
          text: 'Edit Session',
          onClick: onEdit,
        },
        config?.showCopyStreamId && {
          kind: 'button',
          key: 'copyStreamId',
          icon: <CopyIcon />,
          text: 'Copy Stream Id',
          onClick: () => {
            copy(SessionUtils.GetStreamId(sessionTrack));
          },
        },
        config?.showInspect && {
          kind: 'link',
          key: 'inspectSession',
          icon: <SearchIcon />,
          text: 'Inspect Session',
          href: SessionUtils.ComposeInspectSessionUrl(sessionTrack),
        },
      ].filter(Boolean) as Action<Keys>[],
    [
      config?.showCopyStreamId,
      config?.showEdit,
      config?.showInspect,
      onEdit,
      sessionTrack,
    ]
  );
  return <ActionSheet<Keys> actions={actions} placement='left' />;
}
