import { Link } from '@remix-run/react';
import { useCallback, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useListLoader } from '../../hooks/useListLoader';
import {
  apiService,
  type SessionTrackListResponse,
  type SessionTrackSearchParams,
} from '../../services/api-service';
import {
  type Organization,
  type SessionTrack,
  SessionUtils,
} from '../../types';
import { err2s } from '../../utils/common';
import { TimeUtils } from '../../utils/time';
import { Modal } from '../common/Modal';
import { ErrorMessage } from '../Game/GameCenter';
import { Loading } from '../Loading';
import { OrganizationSelect } from '../Organization';
import {
  SessionActionSheet,
  type SessionActionSheetConfig,
} from './SessionActionSheet';

type Config = {
  organizer?: boolean;
  mode?: boolean;
  org?: boolean;
  actionSheet?: SessionActionSheetConfig;
  controller?: boolean;
  adminLinks?: boolean;
  localTime?: boolean;
};

function formatDatetime(datetime: string, timeZone?: string): string {
  const date = new Date(datetime);
  const dateFormatted = new Intl.DateTimeFormat('en-US', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
  const timeFormatted = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeStyle: 'short',
  }).format(date);
  return `${dateFormatted} at ${timeFormatted}`;
}

function EditSessionModal(props: {
  sessionTrack: SessionTrack;
  onCancel: () => void;
  onSave: (sessionTrack: SessionTrack) => void;
}): JSX.Element | null {
  const { sessionTrack } = props;
  const [orgId, setOrgId] = useState<string | null>(sessionTrack.orgId);

  const {
    state: { transformed: callState },
    error,
    call,
  } = useAsyncCall(
    useCallback(
      async (orgId: string | null) => {
        const resp = await apiService.session.updateSession(
          sessionTrack.venueId,
          sessionTrack.id,
          {
            orgId,
          }
        );
        return resp.data.session;
      },
      [sessionTrack.id, sessionTrack.venueId]
    )
  );

  const onOrgChange = (org: Organization | null): void => {
    setOrgId(org?.id || null);
  };

  const onSave = async () => {
    const updatedSessionTrack = await call(orgId);
    if (!updatedSessionTrack) return;
    props.onSave(updatedSessionTrack);
  };

  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      <div className='w-160 flex flex-col items-center text-white px-7'>
        <h2 className='text-2xl mt-7.5'>Edit Session</h2>
        <div className='w-full my-7.5'>
          <label className='font-bold'>
            <div className='mb-2'>
              <span className='text-white'>Organization</span>
            </div>
            <div className='mb-5'>
              <OrganizationSelect
                orgId={sessionTrack.orgId}
                onChange={onOrgChange}
              />
            </div>
          </label>
          <div className='text-red-005 text-xs'>{err2s(error)}</div>
          <div className='w-full mt-7.5 flex flex-row justify-center'>
            <button
              type='button'
              onClick={props.onCancel}
              className='btn-secondary w-40 h-10 text-base'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-40 h-10 ml-5 text-base flex items-center justify-center'
              disabled={callState.isRunning}
              onClick={onSave}
            >
              {callState.isRunning && (
                <Loading text='' containerClassName='mr-2' />
              )}
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function sessionMode(
  sessionTrack: SessionTrack,
  withOnDController = false
): string {
  if (!withOnDController || !sessionTrack.onDemandControllerKind)
    return sessionTrack.mode;
  return `${sessionTrack.mode}(${sessionTrack.onDemandControllerKind})`;
}

export function SessionInspectorLink(props: {
  session: SessionTrack;
  children?: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <Link
      className={`hover:underline ${props.className ?? ''}`}
      to={SessionUtils.ComposeInspectSessionUrl(props.session)}
    >
      {props.children}
    </Link>
  );
}

function SessionInspectorLinkInternal(props: {
  session: SessionTrack;
  children?: React.ReactNode;
  className?: string;
  config?: Config;
}) {
  return props.config?.adminLinks ? (
    <SessionInspectorLink {...props}></SessionInspectorLink>
  ) : (
    <>{props.children}</>
  );
}

export function GamePackMiniGameLink(props: {
  session: SessionTrack;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}): JSX.Element {
  // TODO(drew): hard-coding routes like this should be avoided. This component
  // could be called from a non-admin route, for example.
  const href = props.session.gamePackId
    ? `/admin/gamepacks?packId=${props.session.gamePackId}`
    : props.session.gameId
    ? `/admin/minigames?gameId=${props.session.gameId}`
    : null;
  const name =
    (props.session.gamePackName
      ? props.session.gamePackName
      : props.session.gameName) ?? 'N/A';

  return !props.disabled && href ? (
    <Link className={`hover:underline ${props.className ?? ''}`} to={href}>
      {name}
      {props.children}
    </Link>
  ) : (
    <>
      {name}
      {props.children}
    </>
  );
}

function GamePackMiniGameLinkInternal(props: {
  session: SessionTrack;
  children?: React.ReactNode;
  className?: string;
  config?: Config;
}) {
  return (
    <GamePackMiniGameLink {...props} disabled={!props.config?.adminLinks} />
  );
}

function SessionTrackItem(props: {
  sessionTrack: SessionTrack;
  onEdit?: () => void;
  config?: Config;
}): JSX.Element {
  const { sessionTrack, onEdit, config } = props;
  const durationMs = (sessionTrack.durationSeconds ?? 0) * 1000;

  return (
    <tr className='text-sms'>
      {config?.org && <td>{sessionTrack.organizationName ?? 'N/A'}</td>}
      {config?.organizer && <td>{sessionTrack.hostName}</td>}
      <td>
        <GamePackMiniGameLinkInternal config={config} session={sessionTrack} />
      </td>
      {config?.mode && (
        <td>
          <p>{sessionMode(sessionTrack, config.controller)}</p>
          {sessionTrack.platform && <p>{sessionTrack.platform}</p>}
        </td>
      )}
      <td>
        <SessionInspectorLinkInternal config={config} session={sessionTrack}>
          {TimeUtils.DurationAutoFormattedHHMMSS(durationMs)}
        </SessionInspectorLinkInternal>
      </td>
      <td>
        <SessionInspectorLinkInternal config={config} session={sessionTrack}>
          {sessionTrack.maxPlayers ?? 0}
        </SessionInspectorLinkInternal>
      </td>
      <td>
        <SessionInspectorLinkInternal config={config} session={sessionTrack}>
          <div>{formatDatetime(sessionTrack.startedAt)}</div>
          {config?.localTime && (
            <div>
              {sessionTrack.timeZone &&
                `(${formatDatetime(
                  sessionTrack.startedAt,
                  sessionTrack.timeZone
                )}, ${sessionTrack.timeZone})`}
            </div>
          )}
        </SessionInspectorLinkInternal>
      </td>
      {config?.actionSheet && onEdit && (
        <td>
          <SessionActionSheet
            config={config.actionSheet}
            sessionTrack={sessionTrack}
            onEdit={onEdit}
          />
        </td>
      )}
    </tr>
  );
}

export function SessionTrackTable(props: {
  items: SessionTrack[];
  config?: Config;
  setEditingSessionTrack?: (s: SessionTrack) => void;
}) {
  const { items, config, setEditingSessionTrack } = props;
  return (
    <table
      className='w-full'
      style={{ borderCollapse: 'separate', borderSpacing: '0 0.25rem' }}
    >
      <thead>
        <tr className='text-left'>
          {config?.org && <th>Organization</th>}
          {config?.organizer && <th>Organizer</th>}
          <th>Game Pack Played</th>
          {config?.mode && <th>Mode</th>}
          <th>Session Duration</th>
          <th>Max Players</th>
          <th>Date & Time Played</th>
          {config?.actionSheet && <th></th>}
        </tr>
      </thead>
      <tbody>
        {items.map((i) => (
          <SessionTrackItem
            key={i.id}
            sessionTrack={i}
            onEdit={() => setEditingSessionTrack?.(i)}
            config={config}
          />
        ))}
      </tbody>
    </table>
  );
}

export function SessionTrackHistory(props: {
  searchParams?: SessionTrackSearchParams;
  useDefaultFilters?: boolean;
  config?: Config;
}): JSX.Element | null {
  const { searchParams, config } = props;
  const useDefaultFilters =
    props.useDefaultFilters === undefined ? true : props.useDefaultFilters;
  const paginator = useMemo(
    () =>
      apiService.session.search({
        ...searchParams,
        minPlayers: useDefaultFilters ? 2 : undefined,
        minDurationSeconds: useDefaultFilters ? 120 : undefined,
      }),
    [searchParams, useDefaultFilters]
  );
  const { items, dao, state, error, handleLoadMore, handleRetry } =
    useListLoader<SessionTrackListResponse, SessionTrack>(
      paginator,
      (a, b) => a.id === b.id
    );
  const [editingSessionTrack, setEditingSessionTrack] =
    useState<SessionTrack | null>(null);

  const handleSessionUpdated = useCallback(
    (sessionTrack: SessionTrack) => {
      dao.updateItem(sessionTrack);
      setEditingSessionTrack(null);
    },
    [dao]
  );

  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();
  return (
    <div>
      <SessionTrackTable
        config={config}
        items={items}
        setEditingSessionTrack={setEditingSessionTrack}
      />
      <div>
        {state.isRunning && (
          <div className='flex items-center justify-center mt-50'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            No Sessions.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
      {editingSessionTrack && (
        <EditSessionModal
          sessionTrack={editingSessionTrack}
          onCancel={() => setEditingSessionTrack(null)}
          onSave={handleSessionUpdated}
        />
      )}
    </div>
  );
}
